/* eslint-disable import/prefer-default-export */
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import { Datetime } from 'vue-datetime';
import VueCookieBot from '@ambitiondev/vue-cookiebot-plugin';
import Multiselect from 'vue-multiselect';
import { createRouter } from './router';
import { createStore } from './store';
import Auth from './mixins/Auth';
import isServer from './utils/isServer';
import App from './App.vue';

import './filters';
import './directives';

Vue.component('datetime', Datetime);
Vue.component('multiselect', Multiselect);

Vue.mixin(Auth);

Vue.use(require('vue-cookies'));

if (!isServer) {
  const { ValidationObserver, ValidationProvider } = require('vee-validate');
  Vue.component('ValidationObserver', ValidationObserver);
  Vue.component('ValidationProvider', ValidationProvider);
  Vue.use(VueCookieBot, {
    cookieBotID: '3a0809e1-fd17-4568-8481-3f5771966224',
  });

  const { Vuetable } = require('vuetable-2');
  Vue.component('vuetable', Vuetable);

  const Toasted = require('vue-toasted').default;
  Vue.use(Toasted, {
    position: 'bottom-center',
    duration: 8000,
    type: 'error',
  });

  const { Icon } = require('leaflet');
  // eslint-disable-next-line no-underscore-dangle
  delete Icon.Default.prototype._getIconUrl;
  Icon.Default.mergeOptions({
    iconRetinaUrl: require('@/assets/images/generic/map-marker-alt-2x.png'),
    iconUrl: require('@/assets/images/generic/map-marker-alt-1x.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });

  require('leaflet/dist/leaflet.css');
  require('vue-datetime/dist/vue-datetime.css');
}

export function createApp() {
  const router = createRouter();
  const store = createStore();

  sync(store, router);

  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  });

  return { app, router, store };
}
